import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import Button from "@mui/material/Button";
import { Outlet, Link } from "react-router-dom";

import RenovarPlacas from "./img/start/Agent-Assist-Renovar-Placas.png";
import CaliforniaCambioDeNombre from "./img/start/california-cambio-de-nombre.png";
import ReemplazarRegistracion from "./img/start/Agent-Assist-Reemplazar-registracion.png";
import ReporteParaCompradores from "./img/start/reporte-para-compradores.png";

const Home = () => {
  return (
    <Box
      id="AppBoardStart"
      className="AppBoardScreen AppBoardStart StartAppBoardScreen"
      sx={{ width: 800, m: "auto", backgroundColor: "#fefefe", p: 6.25 }}
    >
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <Link to="/">
            <Button variant="outlined" size="large">
              Regresar
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <h1 className="mainHeading">DMV Options</h1>
        </Grid>
        <Grid item xs={12}>
          <Link to="/dmv/placas/">
            <img src={RenovarPlacas} alt="Renovar Placas" />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="/dmv/cambio/">
            <img
              src={CaliforniaCambioDeNombre}
              alt="California Cambio De Nombre"
            />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="/">
            <img src={ReemplazarRegistracion} alt="Reemplazar Registracion" />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="/">
            <img src={ReporteParaCompradores} alt="Reporte Para Compradores" />
          </Link>
        </Grid>
        <Outlet />
      </Grid>
    </Box>
  );
};

export default Home;
